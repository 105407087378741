<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Stock Forecasting</h2>
            </CCardHeader>

            <CCardBody>
                <CSelect id="StorageId" class="font-weight-bold" :options="StorageIdData" :value.sync="StorageId" @change="onChange()"/>

                <div class="col-xs-6 text-light pb-2">
                    <button id="pdf" class="btn btn-secondary" @click="pdfClick()">
                        <i class="fa fa-file-pdf-o"></i> PDF
                    </button>&nbsp;
                    <!-- <button type="button" class= "btn btn-primary" v-on:click="exportExcel">
                        <i class="fa fa-file-excel-o"></i> Excel
                    </button> -->
                </div>

                <forecasting-stock-grid :key="gridReload" v-bind:stock-id="this.StorageId"/>
                <forecasting-stock-pdf ref="pdf" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import forecastingStockService from '../ForecastingStock/Script/ForecastingStockServices.js';
import forecastingStockGrid from '../ForecastingStock/Grid/ForecastingStockGrid.vue';
import forecastingStockPdf from '../../../assets/template/ForecastingStockPdfTemplate.vue';

export default {
    name: 'Department',
    components: {
        'forecasting-stock-grid': forecastingStockGrid,
        'forecasting-stock-pdf': forecastingStockPdf
    },
    async mounted () {
        this.StorageIdData = await forecastingStockService.getStorageId();
        this.StorageId = this.StorageIdData[0].value;
    },
    data(){
        return{
            StorageId: '',
            StorageIdData : [],
            gridReload: 0,
        }
    },
    methods: {
        onChange(){
            this.reload();
        },
        reload(){
            this.gridReload++;
        },
        pdfClick() {
            var id = this.StorageId
            const routeData = this.$router.resolve({name: 'Forecasting Stock Html Template', params: { id:id }});
            window.open(routeData.href, '_blank');
        },
        
    }
}
</script>

<style scoped>
</style>